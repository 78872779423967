exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-building-a-site-in-a-couple-weekends-mdx": () => import("./../../../src/pages/building-a-site-in-a-couple-weekends.mdx" /* webpackChunkName: "component---src-pages-building-a-site-in-a-couple-weekends-mdx" */),
  "component---src-pages-creating-a-site-a-company-can-rally-around-mdx": () => import("./../../../src/pages/creating-a-site-a-company-can-rally-around.mdx" /* webpackChunkName: "component---src-pages-creating-a-site-a-company-can-rally-around-mdx" */),
  "component---src-pages-generating-storyclocks-mdx": () => import("./../../../src/pages/generating-storyclocks.mdx" /* webpackChunkName: "component---src-pages-generating-storyclocks-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reads-js": () => import("./../../../src/pages/reads.js" /* webpackChunkName: "component---src-pages-reads-js" */),
  "component---src-pages-resurrecting-mockups-mdx": () => import("./../../../src/pages/resurrecting-mockups.mdx" /* webpackChunkName: "component---src-pages-resurrecting-mockups-mdx" */),
  "component---src-pages-revamp-a-review-card-for-a-college-internship-reviews-platform-mdx": () => import("./../../../src/pages/revamp-a-review-card-for-a-college-internship-reviews-platform.mdx" /* webpackChunkName: "component---src-pages-revamp-a-review-card-for-a-college-internship-reviews-platform-mdx" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-writing-an-obsidian-plugin-mdx": () => import("./../../../src/pages/writing-an-obsidian-plugin.mdx" /* webpackChunkName: "component---src-pages-writing-an-obsidian-plugin-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-building-a-site-in-a-couple-weekends-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/building-a-site-in-a-couple-weekends.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-building-a-site-in-a-couple-weekends-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-creating-a-site-a-company-can-rally-around-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/creating-a-site-a-company-can-rally-around.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-creating-a-site-a-company-can-rally-around-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-generating-storyclocks-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/generating-storyclocks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-generating-storyclocks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-resurrecting-mockups-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/resurrecting-mockups.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-resurrecting-mockups-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-revamp-a-review-card-for-a-college-internship-reviews-platform-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/revamp-a-review-card-for-a-college-internship-reviews-platform.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-revamp-a-review-card-for-a-college-internship-reviews-platform-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-pages-writing-an-obsidian-plugin-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/src/pages/writing-an-obsidian-plugin.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-pages-writing-an-obsidian-plugin-mdx" */)
}

